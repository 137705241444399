import React from 'react';
import { injectIntl } from 'react-intl';
import Block from '@UI/Block';

function NotFound({ intl, staticContext }) {
  if (staticContext) {
    staticContext.status = 404;
  }
  return (
    <div className="layout-container">
      <header className="header header--404 bg-variant-brand-quinary">
        <div className="header__wrapper wrapper">
          <div className="header__content header__content--full-width content-block">
            <h1 className="content-block__title">
              <span className="content-block__title-top">{intl.formatMessage({ id: 'NotFound.TitleTop' })}</span>
              <span className="content-block__title-bottom">{intl.formatMessage({ id: 'NotFound.TitleBottom' })}</span>
            </h1>
            <p className="content-block__description">{intl.formatMessage({ id: 'NotFound.Description' })}</p>
          </div>
          <div className="header__media media-block media-block--full-width">
            <div id="lottie" />
          </div>
        </div>
      </header>

      <Block contentSize="s" title={intl.formatMessage({ id: 'NotFound.Title' })}>
        <div className="body-copy">
          <p>{intl.formatMessage({ id: 'NotFound.Text' })}</p>
          <a href="/" className="button">{intl.formatMessage({ id: 'NotFound.Cta' })}</a>
        </div>
      </Block>

    </div>
  );
}

export default injectIntl(NotFound);
